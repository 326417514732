import * as Yup from 'yup'

const phoneRegExp = /^[0-9]{10}$|^\(0[1-9]{1}\)[0-9]{8}$|^[0-9]{8}$|^[0-9]{4}[ ][0-9]{3}[ ][0-9]{3}$|^\(0[1-9]{1}\)[ ][0-9]{4}[ ][0-9]{4}$|^[0-9]{4}[ ][0-9]{4}$/

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .required('Name is Required!'),
  address: Yup.string()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .required('Address is Required!'),    
  email: Yup.string()
    .email('Enter a Valid Email!')
    .required('Email is Required!'),
  phone: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid!')
    .required('Phone number is Required!'),
  message: Yup.string()
    .required('Message is Required!'),
})

export default validationSchema
