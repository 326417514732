import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { useFirebase } from "gatsby-plugin-firebase"

import {
  FaHome,
} from 'react-icons/fa';

import { RuralForm } from '../forms/RuralForm';

const RuralCommunityPageTemplate = ({ title, subtitle, meta_title, meta_description }) => {
  useFirebase(firebase => {
    firebase
      .analytics()
      .logEvent("rural_community_page")
  }, [])

  return <div>
    <Helmet>
      <title>{meta_title}</title>
      <meta name='description' content={meta_description} />
    </Helmet>

    <section className='hero is-burlywood is-bold'>
      <div className='hero-body'>
        <div className='container'>
          <div className='columns is-vcentered'>

            <div>
              <div className='section'>
                <h1 className='title has-text-weight-semibold is-size-3'>{title}</h1>
                <h2 className='is-size-5'>{subtitle}</h2>

                <h2 className='has-text-justified mt-1 is-size-6'>
                  We have been hearing a lot of complaints about a shortage. In reality, Australia has oversupply of pharmacists in major cities.
                </h2>  
                
                <h2 className='has-text-justified mt-1 is-size-6'>
                  The permanent answer to this is no other than overseas pharmacists. Otherwise, the government would need to give more incentives to attract city locals to relocate. 
                </h2>
                
                <h2 className='has-text-justified mt-1 is-size-6'>                  
                  A lot of variables are to be considered in putting a system to solve this imbalance issue and therefore the only thing that can make a big difference is you --- employers.
                  Let's help each other to make the Australian community healthy again with a presence of a pharmacy and a pharmacist in every corner of our country.
                </h2>
              </div>
            </div>

            <div className="column is-6 is-flex is-horizontal-center is-hidden-mobile">
              <figure className="image is4by3 mt-2">
                <FaHome size={340} color={'#f7e151'} />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className='section'>
      <div className='container'>
        <h2 className='has-text-centered is-size-4 has-text-weight-semibold pg-1'>RURAL OR REGIONAL EMPLOYERS</h2>
        <h2 className='is-size-5 mb-2'>If you were having difficulties in filling a rural pharmacist position and are willing to sponsor an intern to solve this problem, feel free to fill in the form below.</h2>

        <RuralForm />
      </div>
    </section>
  </div>
}

RuralCommunityPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
}

export default RuralCommunityPageTemplate
